import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setAdmin } from '../action/app'

type Props = {
  admin: boolean
  setAdminState: any
}

const Admin = ({ setAdminState, admin }: Props) => {
  const keysPressed: string[] = []
  const keys = ['c', 'd']

  const checkKeys = () => {
    if (keysPressed.indexOf(keys[0]) !== -1 && keysPressed.indexOf(keys[1]) !== -1) {
      setAdminState(true)
    }
  }

  const handleRefresh = () => {
    window.location.reload()
  }

  useEffect(() => {
    const keyDown = (event: KeyboardEvent) => {
      if (keysPressed.indexOf(event.key) === -1) {
        keysPressed.push(event.key)
      }
      checkKeys()
    }

    const keyUp = (event: KeyboardEvent) => {
      keysPressed.splice(keysPressed.indexOf(event.key), 1)
    }

    document.addEventListener('keydown', keyDown)
    document.addEventListener('keyup', keyUp)
  }, [])

  return (
    <div>
      {admin === true && (
        <div className="admin">
          <p>Vous etes admin.</p>
          <button onClick={handleRefresh}>Force refresh</button>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = () => ({})
const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      setAdminState: setAdmin,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)<any>(Admin)

const oneDay = 24 * 60 * 60

export const minutesToHours = (minutes: number) => {
  const hours = Math.floor(minutes / 60)

  return [hours, Math.ceil(minutes - (hours * 60))]
}

export const minutesToString = (minutes: number) => {
  return minutesToHours(minutes).join('H')
}

export const getHourTimestamp = (date = new Date()) => {
  return date.getHours() * 60 * 60 + date.getMinutes() * 60
}

export const getNextTime = (currentDuration: number) => {
  let nextTime = currentDuration + getHourTimestamp()
  if (nextTime > oneDay) {
    nextTime = nextTime - oneDay
  }

  return nextTime
}

import React from 'react'
import Clock from '../asset/icons/clock.svg'
import Shuffle from '../asset/icons/shuffle.svg'
import Vinyl from '../asset/icons/vinyl.svg'

import { getPlaylistDuration } from '../helper/playlist'
import { minutesToString } from '../helper/time'

type Props = {
  playlist: any
  list?: boolean
}

const playlistHeader = (props: Props) => {
  const { playlist } = props
  const duration = Math.round((getPlaylistDuration(playlist) / 60) * 100) / 100
  return (
    <div className={props.list ? 'playlist-panel-header list' : 'playlist-panel-header'}>
      <p>{playlist.name}</p>
      <div className="header">
        <span className="item time">
          <img src={Clock} alt="time" />
          <span>
            {playlist.starttime} - {playlist.endtime}
          </span>
        </span>
        <span className="item duration">
          <img src={Shuffle} alt="shuffle" />
          <span>{minutesToString(duration)}’</span>
        </span>
        <span className="item tracks">
          <img src={Vinyl} alt="tracks" />
          <span className="number">{playlist.tracks.length}</span>
        </span>
      </div>
    </div>
  )
}

export default playlistHeader

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Skip from '../asset/icons/skip.png'

import MuteButton from './MuteButton'
import BigPlayButton from './BigPlayButton'
import { nextTrack as nextTrackAction } from '../action/playlist'
import { State } from '../reducer'

type props = {
  nextTrack: () => void
  skippable: boolean
}
const AudioManager = (props: props) => {
  const { nextTrack, skippable } = props

  // prevent click spam, 5000ms is the fadein/out time, and prevents from buffer error
  const handleClick = () => {
    nextTrack()
  }
  return (
    <div className="action-buttons">
      <MuteButton />
      <BigPlayButton />
      <button onClick={handleClick} className="skip-button" disabled={!skippable}>
        <img src={Skip} alt="skip" />
      </button>
    </div>
  )
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      nextTrack: nextTrackAction,
    },
    dispatch
  )

const mapStateToProps = (state: State) => ({
  skippable: state.audio.currentTime > 0, // click debounce, avoid to load another audio if the current one is not loaded
})

export default connect(mapStateToProps, mapDispatchToProps)(AudioManager)

import { useEffect } from 'react'
import config from '../config.json'

type props = {
  ipAddress: string
}
const getIpStackEndpoint = (ip: string) => {
  return config.ipStackEndpoint.replace('{ip}', ip)
}
export const Ipstack = (props: props) => {
  useEffect(() => {
    fetch(getIpStackEndpoint(props.ipAddress))
  }, [props.ipAddress])
  return <></>
}

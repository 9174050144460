import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {createStore, combineReducers, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import {createLogger} from 'redux-logger'

import 'babel-polyfill'
// polyfill for IE10-, Safari 9-, and browsers not having the FR locale: http://caniuse.com/#feat=internationalization

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/css/bootstrap-theme.css'

import './index.css'
import config from './config.json'

import App from './App'

import reducers from './reducer'
import * as serviceWorker from './serviceWorker'

const rootReducer = combineReducers(reducers)
let store
if (config.dev === true) {
  const logger = createLogger({
    errorTransformer: (error) => {
      console.error(error) // eslint-disable-line no-console
      return error
    },
    collapsed: true,
  })
  store = createStore(rootReducer, applyMiddleware(thunk))
} else {
  store = createStore(rootReducer, applyMiddleware(thunk))
}

const app = (
  <Provider store={store}>
    <App />
  </Provider>
)

ReactDOM.render(app, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register()

export const canAutoplay = (): Promise<boolean> => {
  return new Promise((resolve) => {
    const audio = document.createElement('audio')
    // very small Base64 mp3 file
    audio.src = 'data:audio/mpeg;base64,/+MYxAAAAANIAUAAAASEEB/jwOFM/0MM/90b/+RhST//w4NFwOjf///PZu////9lns5GFDv//l9GlUIEEIAAAgIg8Ir/JGq3/+MYxDsLIj5QMYcoAP0dv9HIjUcH//yYSg+CIbkGP//8w0bLVjUP///3Z0x5QCAv/yLjwtGKTEFNRTMuOTeqqqqqqqqqqqqq/+MYxEkNmdJkUYc4AKqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq'

    audio.onerror = () => resolve(false)
    audio.addEventListener('play', () => resolve(true))
    audio.play().catch(() => resolve(false)).then(() => resolve(true))
  })
}

import React, {Component} from 'react'
import logo from '../asset/logo-cds.gif'
import logoSmall from '../asset/logo-cds-small.svg'

type Props = {
  small?: boolean
}

const Logo = (props: Props) => 
      <img src={props.small ? logoSmall : logo} className="logo" alt="logo" />

export default Logo

import React, { useEffect, useState } from 'react'
import Logo from './Logo'
import { connect } from 'react-redux'
import { State } from '../reducer'

type props = {
  initiated: boolean
}
const SplashScreen = (props: props) => {
  const loaded = 50
  const total = 100
  const { initiated } = props
  const [pc, setPc] = useState(Math.round((loaded / total) * 100 || 0))
  const splashscreen = React.useRef<HTMLDivElement>(null)
  const progressBar = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (initiated === false && splashscreen.current) {
      splashscreen.current.style.display = 'flex'
    }
  }, [splashscreen.current, initiated])

  useEffect(() => {
    setPc(Math.round((loaded / total) * 100 || 0))
  }, [loaded, total])

  useEffect(() => {
    if (progressBar.current) {
      progressBar.current.style.width = pc + '%'
    }
  }, [pc, progressBar])

  const message = 'Loading'
  return (
    <div className="splashscreen" ref={splashscreen}>
      <Logo />
      <div className="splashscreen__text" style={{ position: 'absolute', width: '100%', bottom: '40px' }}>
        <p>
          {message}
          <span>.</span>
        </p>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className="progress">{pc} %</span>
          <p className="splashscreen-progressbar">
            <span className="bar" ref={progressBar}></span>
          </p>
        </div>
        {/* <button>Hello</button> */}
      </div>
    </div>
  )
}

const mapStateToProps = (state: State) => {
  return {
    initiated: state.app.initiated,
  }
}

export default connect(mapStateToProps)(SplashScreen)

import { Dispatch, SyntheticEvent } from 'react'
import { State } from '../reducer'
import { Track } from '../model/model'

export enum errorType {
  AUDIO_ERROR = 'AUDIO_ERROR',
  FETCH_TRACK_ERROR = 'FETCH_TRACK_ERROR',
}

export enum actions {
  AUDIO_PLAY_REQUESTED = 'AUDIO_PLAY_REQUESTED',
  AUDIO_PAUSE = 'AUDIO_PAUSE',
  AUDIO_MUTE = 'AUDIO_MUTE',
  AUDIO_UNMUTE = 'AUDIO_UNMUTE',
  AUDIO_ERROR = 'AUDIO_ERROR',
  AUDIO = 'AUDIO',
  AUDIO_EVENT = 'AUDIO-EVENT',
}

type DefaultDispatcher = {
  type: actions
}

export type AudioErrorDispatcher = DefaultDispatcher & {
  error: AudioErrorData
}
export type AudioErrorData = {
  type: errorType
  track: Track
  error?: MediaError | TypeError
}

export type AudioDispatcher = DefaultDispatcher & {
  audio: HTMLAudioElement
}

export type AudioEventDispatcher = DefaultDispatcher & {
  event: SyntheticEvent<HTMLAudioElement, Event>
}

const setAudioError =
  (track: Track, errorType: errorType, error?: MediaError | TypeError) =>
  (dispatch: Dispatch<AudioErrorDispatcher>) => {
    dispatch({
      type: actions.AUDIO_ERROR,
      error: {
        type: errorType,
        track,
        error,
      },
    })
  }

const play = () => (dispatch: Dispatch<DefaultDispatcher>) => {
  dispatch({
    type: actions.AUDIO_PLAY_REQUESTED,
  })
}

const pause = () => (dispatch: Dispatch<DefaultDispatcher>) => {
  dispatch({
    type: actions.AUDIO_PAUSE,
  })
}

const mute = () => (dispatch: Dispatch<DefaultDispatcher>) => {
  dispatch({ type: actions.AUDIO_MUTE })
}

const unmute = () => (dispatch: Dispatch<DefaultDispatcher>) => {
  dispatch({ type: actions.AUDIO_UNMUTE })
}

const seek = (to: number) => (_: any, getState: () => State) => {
  getState().audio.element.currentTime = to
}

export const events = {
  durationchange: 'durationchange',
  volumechange: 'volumechange',
  ended: 'ended',
  play: 'play',
  playing: 'playing',
  pause: 'pause',
  timeupdate: 'timeupdate',
  error: 'error',
  loadstart: 'loadstart',
  progress: 'progress',
  loadend: 'loadend',
}
const eventsToHandle = [
  events.durationchange,
  events.volumechange,
  events.ended,
  events.play,
  events.playing,
  events.pause,
  events.timeupdate,
  events.error,
  events.loadstart,
  events.progress,
  events.loadend,
]

const handleEvents = (dispatch: Dispatch<AudioEventDispatcher>) => {
  return (evt: SyntheticEvent<HTMLAudioElement, Event>) => {
    if (eventsToHandle.indexOf(evt.type) > -1) {
      dispatch({
        type: actions.AUDIO_EVENT,
        event: evt,
      })
    }
  }
}

let registeredEvent: any = {}
const registerAudio = (audio: HTMLAudioElement) => {
  return (dispatch: any, getState: () => State) => {
    const element = getState().audio.element
    if (element !== audio) {
      Object.keys(registeredEvent).forEach(eventName => {
        element.removeEventListener(eventName, registeredEvent[eventName])
      })
      dispatch({
        type: actions.AUDIO,
        audio: audio,
      })

      // all audio event will dispatch an AUDIO-EVENT action
      registeredEvent = {}
      eventsToHandle.forEach(eventName => {
        registeredEvent[eventName] = handleEvents(dispatch)
        audio.addEventListener(eventName, registeredEvent[eventName])
      })
    }
  }
}

export { play, pause, mute, unmute, seek, setAudioError, registerAudio }

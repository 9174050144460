import React, { useEffect } from 'react'
import Logo from '../component/Logo'
import { connect } from 'react-redux'

import Playing from './Playing'
import Playlists from './Playlists'
import AudioManager from '../component/AudioManager'
import { State } from '../reducer'
import { bindActionCreators } from 'redux'
import { update } from '../action/app'
import config from '../config.json'
import { Track } from '../model/model'
import Audio from '../component/Audio'

type props = {
  zone: string
  customer: string
  paused: boolean
  lastUpdatedTime: number
  updateZoneAction: (zoneId: string) => void
  currentTrack: Track | null
  zoneId: string | null
}

const Zone = (props: props) => {
  const { zone, paused, customer, lastUpdatedTime, updateZoneAction, currentTrack, zoneId } = props

  useEffect(() => {
    if (Date.now() > config.syncdelay + lastUpdatedTime && zoneId) {
      updateZoneAction(zoneId)
    }
  }, [currentTrack])

  return (
    <div className="container-zone">
      <header>
        <h1>
          <div className="pull-left">
            <Logo small={true} />
            <div className="zone-infos">
              <div className="customer-name">{customer}</div>
              <div className="zone-name">{zone}</div>
            </div>
          </div>
          <div style={{ clear: 'both' }} />
        </h1>
      </header>
      <div className={paused ? 'zone-content paused' : 'zone-content playing'}>
        {paused ? <Playlists /> : <Playing />}
        <AudioManager />
        <Audio />
      </div>
    </div>
  )
}

const mapStateToProps = (state: State) => ({
  zoneId: state.app.zone && state.app.zone.id,
  zone: (state.app.zone && state.app.zone.name) || 'Welcome',
  customer: (state.app.zone && state.app.zone.customerName) || 'Couturier Du Son',
  paused: state.audio.paused,
  lastUpdatedTime: state.app.lastUpdatedTime,
  currentTrack: state.playlist.track,
})

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      updateZoneAction: update,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Zone)

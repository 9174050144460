import React, { Component, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { detect as detectBrowser } from 'detect-browser'

import config from '../config.json'
import { State } from '../reducer'
import xhr from '../helper/xhr'
import { AudioErrorData } from '../action/audio'
import app from '../reducer/app'

const PING_INTERVAL = 1000 * 60

const eventName = {
  PLAY: 'play',
  PAUSED: 'paused',
  PING: 'ping',
  PLAYLIST: 'playlist',
  ERROR: 'error',
  TRACK: 'track',
  INITIATED: 'initiated',
  MUTE: 'mute',
  UNMUTE: 'unmute',
}

const sendDataToSumologic = (type: string, data: { [key: string]: any } = {}) =>
  xhr({
    method: 'POST',
    url: config.sumologicEndpoint,
    headers: {
      'Content-Type': 'text/json',
    },
    body: JSON.stringify(data),
  }).catch(() => console.error('event', type, data))

const browserDetect = detectBrowser()

type props = {
  customerId: number | null
  customerName: string | null
  ipAddress: string | null
  zoneId: string | null
  zoneName: string | null
  tokenId: string | null
  paused: number
  playlistId: number | null
  playlistName: string | null
  trackId: number | null
  trackName: string | null
  currentTime: number | null
  appError: any
  audioError: AudioErrorData | null
  appInitiated: number
  muted: number
  audioElement: HTMLAudioElement
  duration: number
}

const Monitoring = (props: props) => {
  const device = browserDetect?.os
  const browser = browserDetect?.name
  
  const payload = {
    i: props.ipAddress && btoa(props.ipAddress),
    cid: props.customerId,
    cn: props.customerName,
    zid: props.zoneId,
    zn: props.zoneName,
    to: props.tokenId,
    d: device as string,
    br: browser as string,
    p: props.paused,
    plid: props.playlistId,
    pln: props.playlistName,
    tid: props.trackId,
    tn: props.trackName,
    ct: props.currentTime,
    m: props.muted,
    du: props.duration
  }

  const sendData = (event: string, data = {}) => {
    sendDataToSumologic(event, {
      ...payload,
      ...data,
      e: event,
    })
  }

  const [ping, setPing] = useState(0)

  const initiated = props.appInitiated

  useEffect(() => {
    if (initiated) {
      sendData(eventName.PING, payload)
    }
  }, [ping])

  useEffect(() => {
    if (initiated) {
      sendData(eventName.INITIATED)
      const id = setInterval(() => setPing(ping => ping + 1), PING_INTERVAL)
      return () => clearInterval(id)
    }
  }, [initiated]) // launch once

  // when new track is set after nextTrack() call
  // currenttime should correspond to 5 less then the previous duration
  useEffect(() => {
    if (initiated) {
      sendData(eventName.TRACK)
    }
  }, [props.trackId])

  // when the when the duration is updated this means the track is loaded
  useEffect(() => {
    if (initiated && props.duration > 0) {
      sendData(eventName.PLAY)
    }
  }, [props.duration])

  useEffect(() => {
    if (initiated) {
      sendData(eventName.PLAYLIST)
    }
  }, [props.playlistId])

  useEffect(() => {
    if (initiated) {
      sendData(eventName.PAUSED)
    }
  }, [props.paused])

  useEffect(() => {
    if (initiated) {
      sendData(props.muted ? eventName.MUTE : eventName.UNMUTE)
    }
  }, [props.muted])

  useEffect(() => {
    if (props.audioError) {
      sendData(eventName.ERROR, {
        tid: props.audioError.track.id,
        tn: props.audioError.track.name,
        er: props.audioError.type, // error
        erm: props.audioError.error?.message, // error message
      })
    }
  }, [props.audioError])

  useEffect(() => {
    if (props.appError) {
      sendData(eventName.ERROR, {
        er: 'initError', // error
        erm: props.appError.message, // error message
      })
    }
  }, [props.appError])

  return <div />
}

const mapStateToProps = (state: State) => {
  return {
    customerId: state.app.zone && state.app.zone.customerId,
    customerName: state.app.zone && state.app.zone.customerName,
    ipAddress: state.app.zone && state.app.zone.ip,
    zoneId: state.app.zone && state.app.zone.id,
    zoneName: state.app.zone && state.app.zone.name,
    tokenId: state.app.zone && state.app.zone.token,
    paused: state.audio.paused ? 1 : 0,
    playlistId: state.playlist.playlist && state.playlist.playlist.id,
    playlistName: state.playlist.playlist && state.playlist.playlist.name,
    trackId: state.playlist.track && state.playlist.track.id,
    trackName: state.playlist.track && state.playlist.track.name,
    currentTime: Math.round(state.audio.currentTime),
    duration: Math.round(state.audio.duration),
    appError: state.app.error,
    audioError: state.audio.error,
    appInitiated: state.app.initiated ? 1 : 0,
    muted: state.audio.muted ? 1 : 0,
    audioElement: state.audio.element
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Monitoring)

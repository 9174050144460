import React, {Component} from 'react'

import Logo from '../component/Logo'

class ZoneDisabled extends Component {
  render() {
    return (
      <div className="zone-disabled">
        <Logo />
        <h2>Oups !!!!</h2>
        <p>Your player is not activated at this time</p>
        <button className="btn btn-info">Please, contact us</button>
      </div>
    )
  }
}

export default ZoneDisabled

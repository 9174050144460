import React, { Component, MouseEventHandler } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { seek as seekAction } from '../action/audio'
import { State } from '../reducer'
type props = {
  currentTime: number
  duration: number
  loaded: boolean
  ended: boolean
  percentLoaded: number
  seek: (time: number) => void
  admin: boolean
}
class ProgressBar extends Component<props> {
  ref = React.createRef<HTMLDivElement>()
  handleHMS = (value: string | number) => {
    const sec = typeof value === 'string' ? parseInt(value, 10) : value // convert value to number if it's string
    let hours = Math.floor(sec / 3600) // get hours
    let minutes = Math.floor((sec - hours * 3600) / 60) // get minutes
    let seconds = Math.round(sec - hours * 3600 - minutes * 60) //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    let m = isNaN(minutes) ? '-' : `${minutes}`
    let s = isNaN(seconds) ? '-' : `${seconds}`

    if (minutes < 10) {
      m = '0' + minutes
    }
    if (seconds < 10) {
      s = '0' + seconds
    }

    return m + ':' + s // Return is HH : MM : SS
  }

  handleSeek: MouseEventHandler<HTMLDivElement> = evt => {
    if (this.props.admin === true && this.ref.current) {
      const { duration, seek } = this.props

      const barX = (evt.target as any).getBoundingClientRect().left
      const percentRequest = (evt.clientX - barX) / this.ref.current.offsetWidth
      const seekTime = duration * percentRequest

      if (!isNaN(seekTime)) {
        seek(seekTime)
      }
    }
  }

  render() {
    const { currentTime, duration, loaded, ended, percentLoaded } = this.props

    return (
      <div className="progressbar-container">
        <p className="track-duration">{this.handleHMS(duration)}</p>
        <p className="track-actual">{this.handleHMS(currentTime)}</p>
        <div ref={this.ref} onClick={this.handleSeek} className="progressbar-background" id="progressbar-container">
          <div
            className="progressbar bar"
            style={{
              width: ended ? '100%' : (currentTime / duration) * 100 + '%',
            }}
          />
          <div className="loadingbar bar" style={{ width: loaded ? '100%' : percentLoaded + '%' }} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: State) => {
  return {
    currentTime: state.audio.currentTime,
    duration: state.audio.duration,
    loaded: state.audio.loaded,
    ended: state.audio.ended,
    percentLoaded: (state.audio.bytesloaded / state.audio.bytestotal) * 100,
    admin: state.app.admin,
  }
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      seek: seekAction,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(ProgressBar)

import React, {Component} from 'react'
import Sound from '../asset/icons/sound.svg'
import NoSound from '../asset/icons/nosound.svg'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import {mute as muteAction, unmute as unmuteAction} from '../action/audio'
import { State } from '../reducer'

type props = {
  muted: boolean,
  mute: () => void,
  unmute: () => void,
}
class MuteButton extends Component<props> {
  handleClick = () => {
    const {mute, unmute, muted} = this.props

    return muted ? unmute() : mute()
  }

  render() {
    const {muted} = this.props

    return (
      <button type="button" className="mute-button" onClick={this.handleClick} >
        <img src={(muted ? NoSound : Sound)} alt="mute" />
      </button>
    )
  }
}

const mapStateToProps = (state: State) => {
  return {
    muted: state.audio.muted,
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  mute: muteAction,
  unmute: unmuteAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(MuteButton)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Zone from './screen/Zone'
import NoZone from './screen/NoZone'
import ZoneDisabled from './screen/ZoneDisabled'
import SplashScreen from './component/SplashScreen'
import initAction from './action/app'
import Monitoring from './component/Monitoring'
import Admin from './component/Admin'
import { Ipstack } from './component/Ipstack'

class App extends Component {
  componentDidMount() {
    this.props.init()
  }

  componentDidUpdate() {
    if (this.props.track) {
      window.document.title = this.props.track.name.replace(/_/gi, ' ') + ' - Le Couturier Du Son - By Fred Viktor'
    }
  }

  render() {
    const { zone, initiated, noZone } = this.props

    const noZoneRender = noZone ? <NoZone /> : null
    const appRender = initiated ? zone.enabled ? <Zone /> : <ZoneDisabled /> : <SplashScreen />

    return (
      <div className="container-main">
        <Ipstack />
        <Monitoring />
        {noZoneRender}
        {appRender}
        <Admin />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  zone: state.app.zone,
  track: state.playlist.track,
  initiated: state.app.initiated,
  noZone: state.app.error,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      init: initAction,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(App)

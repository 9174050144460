type request = {
  method?: 'POST' | 'GET'
  url: string
  headers: any
  body?: string
  responseType?: XMLHttpRequestResponseType
}

type option = {
  withCredentials?: boolean
  onProgress?: (ev: ProgressEvent<EventTarget>) => void
}

const Ajax = <T>(req: request, options: option = { withCredentials: true }): Promise<T> => {
  const method = req.method || 'GET'
  const headers = req.headers || {}
  const onProgress = options.onProgress || function () {}

  return new Promise((resolve, reject) => {
    // Make an XHR
    const xhr = new XMLHttpRequest()
    xhr.responseType = req.responseType || 'text'

    // When ready try to parse the body in case it could be JSON
    const success = () => {
      resolve(xhr.response)
    }

    const error = (errorCode: number) => reject(new TypeError(`Network request failed (${errorCode}) to ${req.url}`))

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status >= 200 && xhr.status < 300) success()
        else error(xhr.status)
      }
    }

    xhr.onprogress = onProgress

    // Open the request
    xhr.open(method, req.url, true)

    // CORS
    xhr.withCredentials = options.withCredentials || false

    // Set the headers
    const headerKeys = Object.keys(req.headers || {})
    for (let i = 0; i < headerKeys.length; i++) {
      const header = headers[headerKeys[i]]
      xhr.setRequestHeader(headerKeys[i], (req && header) || '')
    }

    // Run
    xhr.send(req.method !== 'GET' ? req.body : undefined)
  })
}

export default Ajax

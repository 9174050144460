import { setPlayerToken } from '../helper/app'
import { Zone } from '../model/model'

export type State = {
  zone: Zone | null
  loading: boolean
  error: any
  apiVersion: number
  initiated: boolean
  admin: boolean
  lastUpdatedTime: number
}
const initialState: State = {
  zone: null,
  loading: true,
  error: null,
  apiVersion: 5,
  initiated: false,
  admin: false,
  lastUpdatedTime: Date.now(),
}

export default (state = initialState, action: any): State => {
  switch (action.type) {
    case 'ZONE':
      const zone: Zone = action.zone
      // register the player token for next xhr
      setPlayerToken(zone.token)
      return {
        ...state,
        loading: false,
        initiated: true,
        lastUpdatedTime: Date.now(),
        zone: {
          ...zone,
          // force de-activate zone without playlists
          enabled: zone.playlists.length === 0 ? false : zone.enabled,
        },
      }
    case 'ZONE_UPDATED':
      return {
        ...state,
        lastUpdatedTime: Date.now(),
        zone: {
          ...action.zone,
          // force de-activate zone without playlists
          enabled: action.zone.playlists.length === 0 ? false : action.zone.enabled,
        },
      }
    case 'ZONE_ERROR':
      return {
        ...state,
        loading: false,
        error: {
          type: 'zoneError',
          error: action.error,
        },
      }
    case 'SET_ADMIN':
      return {
        ...state,
        admin: true,
      }
    default:
      return state
  }
}

import xhr from '../helper/xhr'

export function reload() {
  document.location.reload()
}

export function getPlayerToken() {
  return localStorage.getItem('playerToken') || ''
}

export function setPlayerToken(token: string) {
  localStorage.setItem('playerToken', token)
}

export const fetchTrack = (url: string, onProgress: ((ev : ProgressEvent<EventTarget>) => void) | undefined = undefined) => xhr<ArrayBuffer>({
  url,
  responseType: 'arraybuffer',
  method: 'GET',
  headers: {},
  body: ''
}, {onProgress})

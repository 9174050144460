import React, { SyntheticEvent, useCallback, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { getWaitingMusicUrl, revokeObjectURL, getTrackUrl } from '../helper/offline'
import {
  AudioErrorData,
  errorType as audioErrorType,
  play as playAction,
  registerAudio as registerAudioAction,
  setAudioError as setAudioErrorAction,
} from '../action/audio'
import { nextTrack as nextTrackAction } from '../action/playlist'
import { State } from '../reducer'
import { Track } from '../model/model'
import { canAutoplay } from '../helper/audio'
import { errorType } from '../action/audio'

const FADE_TIME = 5

type audioTagProps = {
  onAudioError: (track: Track, error: MediaError) => void
  setCuepointReached: (track: Track | null) => void
  onEnded: (track: Track | null) => void
  url: string | null
  track: Track | null
}

// Use forwardRef to be able to use ref on the audio tag
const AudioTag = React.forwardRef((props: audioTagProps, ref: React.ForwardedRef<HTMLAudioElement>) => {
  const { onAudioError, setCuepointReached, url, track, onEnded } = props

  const handleError = (event: SyntheticEvent<HTMLAudioElement, Event>) => {
    // better then set next track, we handle the waiting music to be played to avoid infinite nextTrack loop
    if (track && event.currentTarget.error) {
      onAudioError(track, event.currentTarget.error)
    }
  }

  const handleTimeUpdate = (event: SyntheticEvent<HTMLAudioElement, Event>) => {
    const audioElement = event.target as HTMLAudioElement
    if (track && audioElement.duration > 0 && audioElement.currentTime >= audioElement.duration - FADE_TIME) {
      setCuepointReached(track)
    }

    try {
      if (audioElement.currentTime < FADE_TIME) {
        const newVolume = audioElement.currentTime + 0.01 / FADE_TIME
        audioElement.volume = newVolume > 1 ? 1 : newVolume
      } else if (audioElement.duration - audioElement.currentTime < FADE_TIME) {
        const newVolume = (audioElement.duration - audioElement.currentTime + 0.01) / FADE_TIME
        audioElement.volume = newVolume < 0 ? 0 : newVolume
      } else {
        audioElement.volume = 1
      }
    } catch (e) {}
  }

  const handleEnded = () => onEnded(track)

  return (
    <audio
      ref={ref}
      id={(track && track.hash) || ''}
      onError={handleError}
      onTimeUpdate={handleTimeUpdate}
      onEnded={handleEnded}>
      {url ? <source src={url} type="audio/mp3" /> : null}
    </audio>
  )
})

type props = {
  currentTrack: Track | null
  nextTrackToPlay: Track | null
  paused: boolean
  currentAudioElement: HTMLAudioElement
  audioError: AudioErrorData | null
  registerAudio: (audio: HTMLAudioElement) => void
  setAudioError: (track: Track, errorType: errorType, error?: MediaError | TypeError) => void
  nextTrack: () => void
  play(): void
}

function Audio(props: props) {
  const {
    currentTrack,
    paused,
    nextTrack,
    registerAudio,
    setAudioError,
    nextTrackToPlay,
    currentAudioElement,
    play,
    audioError,
  } = props

  const r1 = useRef<HTMLAudioElement>(null)
  const r2 = useRef<HTMLAudioElement>(null)
  const refs = [r1, r2]

  const [cuepointReached, setCuepointReached] = React.useState<Track | null>(null)
  const [trackUrl, setTrackUrlState] = React.useState<[Track, string] | null>(null)
  const [preloadedTrack, setPreloadedTrack] = React.useState<[Track, string] | null>(null)
  const [url1, setUrl1] = React.useState<[Track, string] | null>(null)
  const [url2, setUrl2] = React.useState<[Track, string] | null>(null)
  const [audioActivated, setAudioActivated] = React.useState<boolean>(false)

  const unloadSource = (element: HTMLAudioElement, setUrl: (url: [Track, string] | null) => void) => {
    revokeObjectURL(element.currentSrc)
    setUrl(null)
  }

  const getOfflineTrackUrlWithFallback = (track: Track): Promise<string> =>
    getTrackUrl(track) // try to get file from network
      .catch(error => {
        setAudioError(track, errorType.FETCH_TRACK_ERROR, error)
        return getWaitingMusicUrl()
      })

  const preloadTrack = (track: Track): void => {
    const [preloaded] = preloadedTrack || [null]
    if (!preloaded || (preloaded && preloaded.hash !== track.hash)) {
      getOfflineTrackUrlWithFallback(track) // try to get file from network
        .then(url => setPreloadedTrack([track, url]))
        .catch(console.error)
    }
  }
  const getSafeTrackUrl = (track: Track): Promise<string> => {
    const [preloaded, url] = preloadedTrack || [null, null]
    if (preloaded && preloaded.hash === track.hash) {
      return Promise.resolve(url)
    } else {
      return getOfflineTrackUrlWithFallback(track)
    }
  }

  // 1 --------------------------------------------------------------------------------------------
  // try autoplay if browser handle it
  // this way the sound start when the app is launched
  useEffect(() => {
    if (!audioActivated) {
      canAutoplay().then(autoplay => {
        if (autoplay) {
          play()
        } else {
          console.log('autoplay not allowed')
        }
      })
    }
  }, [audioActivated])

  // 2 --------------------------------------------------------------------------------------------
  // This handle the first play call
  // - handle the user gesture to activate the audio tag on Ios
  // - ask for a next track to be preloaded
  useEffect(() => {
    if (!paused && !audioActivated && r1.current !== null && r2.current !== null) {
      // this activate the audio tag on Ios with user interaction, asuming when paused become false, it's a user interaction
      // need to activate all tags otherwise the sound will be paused on lockscreen when first track complete
      r1.current.play().catch(() => {})
      r2.current.play().catch(() => {})
      setAudioActivated(true)
      nextTrack()
    }
  }, [paused, audioActivated, r1.current, r2.current])

  // 3 --------------------------------------------------------------------------------------------
  // check for the url of the current track
  useEffect(() => {
    if (audioActivated && currentTrack !== null) {
      // when user click on next button, the audio element won't trigger the ended, then we need to remove it to avoid 2 audio play at the same time
      if (cuepointReached === null) {
        // be sure to check all cases, currentAudioElement is coming from redux, it's initialState can be none of the refs
        if (currentAudioElement === r1.current) {
          unloadSource(r1.current, setUrl1)
        } else if (currentAudioElement === r2.current) {
          unloadSource(r2.current, setUrl2)
        }
      }
      // right moment to reset the cuepoint, we should let it when we receive the new track
      setCuepointReached(null)
      getSafeTrackUrl(currentTrack)
        .then(url => setTrackUrlState([currentTrack, url]))
        .catch(console.error) // this should never fail
    }
  }, [currentTrack, audioActivated])

  // 4 --------------------------------------------------------------------------------------------
  // then register track and launch play
  useEffect(() => {
    if (trackUrl !== null) {
      const [audioElement, setUrl] = currentAudioElement === r1.current ? [r2.current, setUrl2] : [r1.current, setUrl1]

      if (audioElement) {
        setUrl(trackUrl)
        registerAudio(audioElement)
      }
    }
  }, [trackUrl])

  // 5 --------------------------------------------------------------------------------------------
  // preload next track, should occur when currentTrack change
  useEffect(() => {
    if (nextTrackToPlay) {
      preloadTrack(nextTrackToPlay)
    }
  }, [nextTrackToPlay])

  // 6 --------------------------------------------------------------------------------------------
  // Handle next track when cue point reached (usally 5 seconds at the end of the track)
  useEffect(() => {
    if (!!cuepointReached && cuepointReached === currentTrack) {
      nextTrack()
    }
  }, [cuepointReached, currentTrack])

  // 7 --------------------------------------------------------------------------------------------
  // handle audio faillure, and try to play the next track
  useEffect(() => {
    if (audioError) {
      const { track, type } = audioError
      if (type === audioErrorType.AUDIO_ERROR && track.hash === currentTrack?.hash) {
        nextTrack()
      }
    }
  }, [audioError])

  //  --------------------------------------------------------------------------------------------
  // Handle play/pause user interaction
  useEffect(() => {
    Object.values(refs).forEach(ref => {
      if (audioActivated) {
        if (paused) {
          ref.current?.pause()
        } else {
          ref.current?.play().catch(() => {})
        }
      }
    })
  }, [paused, audioActivated])

  // we need to call the load function on htmlAudioElement to make compatible with all browsers (mostly safari)
  // if the url is a string, it will start the loading, and play the audio
  // if the url is null, it will unload the content and stop the sound
  useEffect(() => {
    r1.current?.load()
    r1.current?.play().catch(() => {})
  }, [url1])
  useEffect(() => {
    r2.current?.load()
    r2.current?.play().catch(() => {})
  }, [url2])

  const onAudioError = (track: Track, error: MediaError) => {
    setAudioError(track, errorType.AUDIO_ERROR, error)
  }

  const onEnded = useCallback(
    (track: Track | null) => {
      // this assume the cuepoint didn't works, we shouldn't pass here
      // it's a security to avoid the track to be stuck on complete
      if (track === currentTrack) {
        nextTrack()
      }

      // remove the <source> element from DOM and unload the the current data
      if (url1 && url1[0].hash === track?.hash && r1.current) {
        unloadSource(r1.current, setUrl1)
      } else if (url2 && url2[0].hash === track?.hash && r2.current) {
        unloadSource(r2.current, setUrl2)
      }
    },
    [currentTrack, url1, url2, r1.current, r2.current]
  )

  const [t1, u1] = url1 || [null, null]
  const [t2, u2] = url2 || [null, null]

  return (
    <div>
      <AudioTag
        key={1}
        onAudioError={onAudioError}
        setCuepointReached={setCuepointReached}
        ref={r1}
        url={u1}
        track={t1}
        onEnded={onEnded}
      />
      <AudioTag
        key={2}
        onAudioError={onAudioError}
        setCuepointReached={setCuepointReached}
        ref={r2}
        url={u2}
        track={t2}
        onEnded={onEnded}
      />
    </div>
  )
}

const mapStateToProps = (state: State) => ({
  nextTrackToPlay: state.playlist.nextTrack,
  currentTrack: state.playlist.track,
  paused: state.audio.paused,
  currentAudioElement: state.audio.element,
  audioError: state.audio.error,
})

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      registerAudio: registerAudioAction,
      setAudioError: setAudioErrorAction,
      nextTrack: nextTrackAction,
      play: playAction,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Audio)

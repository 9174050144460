import { Track, Playlist } from '../model/model'
import { removeDuplicates, arrayRandom } from './array'

const getPlaylistDuration = (playlist: Playlist) => {
  return playlist.tracks.reduce((duration, track) => {
    return (duration += track.duration || 0)
  }, 0)
}

const findPlaylistByTrack = (playlists: Playlist[], track: Track) => {
  return (
    playlists.find(playlist => {
      return !!playlist.tracks.find(trck => trck.id === track.id)
    }) || null
  )
}

const findTrackByHash = (playlists: Playlist[], hash: string) => {
  for (let i = 0, l = playlists.length; i < l; i++) {
    const track = playlists[i].tracks.find(trck => trck.hash === hash)
    if (track) {
      return track
    }
  }
  return null
}

const toTimestamp = (hours: number, minutes: number, seconds: number) => hours * 60 * 60 + minutes * 60 + seconds
const findCurrentPlaylist = (playlists: Playlist[]) => {
  const date = new Date()
  const timestamp = toTimestamp(date.getHours(), date.getMinutes(), date.getSeconds())

  if (playlists.length === 1 && playlists[0]) {
    return playlists[0]
  }

  return playlists.reduce((acc: Playlist | null, playlist, currentIndex, values) => {
    // if no playlist found, we are for sure in the last one
    if (timestamp >= playlist.timestamp || (currentIndex === values.length - 1 && acc === null)) {
      return playlist
    } else {
      return acc
    }
  }, null)
}

function getPlayed(playlist: Playlist) {
  const playedTracks = (localStorage.getItem('playlistPlayedTracks-' + playlist.id) || '').split(',')
  return playedTracks.map(id => playlist.tracks.find(track => track.id === Number(id))).filter(track => !!track)
}

function getUnplayed(playlist: Playlist) {
  const played = getPlayed(playlist)
  return playlist.tracks.filter(track => !played.find(trck => trck && track.id === trck.id))
}

function setPlayed(tracks: Track[], playlists: Playlist[]) {
  if (!Array.isArray(tracks)) {
    tracks = [tracks]
  }
  const data: { [key: number]: { playlist: Playlist; tracks: Track[] } } = {}
  tracks.forEach(track => {
    const playlist = findPlaylistByTrack(playlists, track)
    if (playlist) {
      const played = (data[playlist.id] && data[playlist.id].tracks) || getPlayed(playlist)
      played.push(track)

      data[playlist.id] = {
        playlist,
        tracks: removeDuplicates(played),
      }
    }
  })

  const persist = (playlist: Playlist, tracks: Track[]) =>
    localStorage.setItem('playlistPlayedTracks-' + playlist.id, tracks.map(track => track.id).join(','))

  Object.keys(data)
    .map(playlistId => Number(playlistId))
    .forEach(playlistId => {
      // when all track played, let half of the last track played to be sure they won't play again sooner
      if (data[playlistId].playlist.tracks.length === data[playlistId].tracks.length) {
        persist(
          data[playlistId].playlist,
          data[playlistId].tracks.slice(Math.floor(data[playlistId].tracks.length) / 2, data[playlistId].tracks.length)
        )
      } else {
        persist(data[playlistId].playlist, data[playlistId].tracks)
      }
    })
}

const getNextTrack = (playlist: any) => arrayRandom(getUnplayed(playlist))

export {
  getPlaylistDuration,
  findPlaylistByTrack,
  findTrackByHash,
  findCurrentPlaylist,
  getPlayed,
  getUnplayed,
  setPlayed,
  getNextTrack,
  toTimestamp,
}

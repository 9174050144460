import config from '../config.json'
import { Playlist, Zone } from '../model/model'
import { getPlayerToken } from './app'
import { toTimestamp } from './playlist'
import xhr from './xhr'

// FIXME , implement better parsing like zod
const parseZone = (zone: any): Zone => {
  const playlists: Playlist[] = zone.playlists
  return {
    ...zone,
    playlists: playlists
      .map((playlist, index, values) => {
        const [hours, minutes] = playlist.starttime.split(':').map(Number)

        return {
          ...playlist,
          timestamp: toTimestamp(hours, minutes, 0),
          endtime: values[index + 1] ? values[index + 1].starttime : values[0] ? values[0].starttime : '00:00',
        }
      })
      .sort((a, b) => {
        const t1 = a.timestamp
        const t2 = b.timestamp
        return t1 === t2 ? 0 : t1 >= t2 ? 1 : -1
      }),
  }
}

export const loadZone = (zoneId: string, apiVersion: number, onProgress = function () {}): Promise<Zone> => {
  const headers = {
    'X-ambiance-api-version': apiVersion,
    'X-ambiance-player-token': getPlayerToken(),
  }

  return xhr<any>(
    {
      url: config.endpoint + '/' + zoneId,
      responseType: 'json',
      headers,
    },
    {
      onProgress,
    }
  ).then(zone => {
    if (zone.error) {
      return Promise.reject(zone.error)
    }
    return parseZone(zone)
  })
}

import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import ProgressBar from '../component/ProgressBar'
import PlaylistHeader from '../component/PlaylistHeader'
import { State } from '../reducer'

type props = {
  playlist: any,
  track: any,
}
class Zone extends Component<props> {
  render() {
    const {playlist, track} = this.props

    return (
      <div className="playing-container">
        <PlaylistHeader playlist={playlist} />
        <div className="default-view playing-view">
          <div className="default-view-content">
            <div className="playlist-name">En cours de lecture : {playlist.name}</div>
            <div className="track-name">{track.name.replace(/_/ig, ' ')}</div>
            <ProgressBar />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: State) => ({
  track: state.playlist.track,
  playlist: state.playlist.playlist,
})

export default connect(mapStateToProps)(Zone)

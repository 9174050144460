import { Track } from '../model/model'
import waitingMusic from '../asset/waiting-music.mp3'
import { fetchTrack } from './app'

declare global {
  interface Window {
    webkitIndexedDB: IDBFactory | undefined
    mozIndexedDB: IDBFactory | undefined
    OIndexedDB: IDBFactory | undefined
    msIndexedDB: IDBFactory | undefined
  }
}

const indexedDB =
  window.indexedDB || window.webkitIndexedDB || window.mozIndexedDB || window.OIndexedDB || window.msIndexedDB
const dbVersion = 1

const getDb = (): Promise<IDBDatabase> => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('ambiance', dbVersion)
    const createObjectStore = (dataBase: IDBDatabase) => {
      dataBase.createObjectStore('audio')
    }

    request.onerror = reject

    request.onsuccess = () => {
      resolve(request.result)
    }

    setTimeout(() => reject(new Error('failed to init db after 10000')), 10000)

    request.onupgradeneeded = (event: any) => {
      createObjectStore(event.target?.result)
    }

    request.onblocked = event => {
      reject(new Error('Blocked ' + event))
    }
  })
}

const clearOfflineData = (): Promise<void> => {
  return getDb().then(db => {
    return new Promise((resolve, reject) => {
      // optimize request by using openCursor which won't read the content
      const transaction = db.transaction(['audio'], 'readwrite')
      const objectStore = transaction.objectStore('audio')
      const clearRequest = objectStore.clear()

      clearRequest.onsuccess = () => resolve()
      clearRequest.onerror = () => reject()
    })
  })
}

// TO BE REMOVED ONCE ALL PLAYER ARE MIGRATED
clearOfflineData().catch(console.error)

const createBlobUrl = (arrayBuffer: ArrayBuffer) =>
  (window.URL || window.webkitURL).createObjectURL(new Blob([new Uint8Array(arrayBuffer)]))
export const revokeObjectURL = (url: string) => (window.URL || window.webkitURL).revokeObjectURL(url)
export const getWaitingMusicUrl = () => {
  const baseUrl = 'data:text/plain;base64,'
  return baseUrl + waitingMusic
}

// this allow to get a blob in all case
export const getTrackUrl = (track: Track) =>
  fetchTrack(track.url) // try to get file from network
    .then(createBlobUrl)

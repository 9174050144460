import React, {Component} from 'react'
import Play from '../asset/icons/play.svg'
import Paused from '../asset/icons/paused.svg'
import {connect} from 'react-redux'
import {Dispatch, bindActionCreators} from 'redux'

import {pause as pauseAction, play as playAction} from '../action/audio'
import { State } from '../reducer'

type Props = {
  paused: boolean,
  audioReady: boolean,
  play: () => void,
  pause: () => void,
}

class BigPlayButton extends Component<Props> {
  state = {}

  handlePlay = () => {
    const {paused, play, pause} = this.props
    if (paused) {
      this.setState({clicked: true})
      play()
    } else {
      this.setState({clicked: false})
      pause()
    }
  }

  render() {
    const {paused, audioReady} = this.props

    return (
      <div className="big-play-button-container">
        <div onClick={() => this.handlePlay()} className={'big-play-button ' + paused}>
          <img src={paused ? Paused : Play} alt="play" />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: State) => ({
  paused: state.audio.paused,
  audioReady: state.audio.ready,
})

const mapDispatchToProps = (dispatch: Dispatch<State>) => bindActionCreators({
  play: playAction,
  pause: pauseAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(BigPlayButton)

import React, {Component} from 'react'
import {connect} from 'react-redux'

import Logo from '../component/Logo'
import ContactUsButton from '../component/ContactUsButton'
import { State } from '../reducer'

type props = {
  error: any
}
class NoZone extends Component<props> {
  render() {
    const {error} = this.props

    return (
      <div className="error-container">
        <Logo />
        <h2>Oups !!!!</h2>
        <p>An error occured, unable to load your player</p>
        {error && <p className="error">{error.message || 'Unknown error'}</p>}
        <ContactUsButton />
      </div>
    )
  }
}

const mapStateToProps = (state: State) => ({
  error: state.app.error,
})

export default connect(mapStateToProps)(NoZone)

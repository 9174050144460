import React, { Component } from 'react'
import { connect } from 'react-redux'
import PlaylistHeader from '../component/PlaylistHeader'
import { nextTrack as nextTrackAction } from '../action/playlist'
import { State } from '../reducer'

type props = {
  playlists: any[]
  nextTrack: () => void
}

class Playlists extends Component<props> {
  render() {
    const { playlists } = this.props

    return (
      <div className="paused-container">
        <div className="playlists-list-container">
          <div className="playlists">
            {playlists.map((playlist, index) => (
              <PlaylistHeader list={true} key={'playlist-header-' + index} playlist={playlist} />
            ))}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: State) => ({
  playlists: state.app.zone?.playlists || [],
  nextTrack: nextTrackAction,
})

export default connect(mapStateToProps)(Playlists)

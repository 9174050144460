import { AudioErrorData, actions, events } from '../action/audio'

export type State = {
  ended: boolean
  volume: number
  currentTime: number
  duration: number
  error: AudioErrorData | null
  seeking: boolean
  src: string
  buffered: TimeRanges
  ready: boolean
  loading: boolean
  loaded: boolean
  bytesloaded: number
  bytestotal: number
  element: HTMLAudioElement
  muted: boolean
  paused: boolean
  activated: boolean
}
// extract data from audio element
const getAudioProperties = (audio: HTMLAudioElement) => {
  return {
    ended: audio.ended,
    volume: audio.volume,
    currentTime: audio.currentTime,
    duration: audio.duration,
    seeking: audio.seeking,
    src: audio.currentSrc,
    buffered: audio.buffered,
  }
}

// init default state with the defaults value of an empty audio tag
const defaultAudio = window.document.createElement('audio')
defaultAudio.volume = 0.1
const initialState: State = {
  activated: false,
  ready: false,
  loading: false,
  loaded: false,
  error: null,
  bytesloaded: 0,
  bytestotal: 0,
  element: defaultAudio,
  muted: false,
  paused: true,
  ...getAudioProperties(defaultAudio),
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    // register the audio element
    case actions.AUDIO:
      const element = action.audio
      element.muted = state.muted
      return {
        ...state,
        // when registering mean we want to play the track, so it's not ended
        element,
        ...getAudioProperties(action.audio),
      }
    case actions.AUDIO_MUTE:
      state.element.muted = true
      return {
        ...state,
        muted: true,
      }
    case actions.AUDIO_UNMUTE:
      state.element.muted = false
      return {
        ...state,
        muted: false,
      }
    case actions.AUDIO_PAUSE:
      return {
        ...state,
        paused: true,
      }
    case actions.AUDIO_ERROR:
      return {
        ...state,
        error: action.error,
      }
    case actions.AUDIO_PLAY_REQUESTED:
      return {
        ...state,
        paused: false,
      }
    // on each audio event, update state
    case actions.AUDIO_EVENT:
      const newState = {
        ...state,
        ...getAudioProperties(action.event.target),
      }

      // prevent to display paused screen when playing when pause on lockscreen on iOS
      if (!state.element.paused) {
        newState.paused = false
      }
      switch (action.event.type) {
        case events.loadstart:
          newState.loading = true
          newState.loaded = false
          newState.bytesloaded = 0
          newState.bytestotal = 0
          newState.ready = false
          break
        case events.progress:
          let loaded = 0
          for (let i = 0, l = newState.buffered.length; i < l; i++) {
            loaded += newState.buffered.end(i) - newState.buffered.start(0)
          }
          newState.bytesloaded = loaded
          newState.bytestotal = Math.floor(newState.duration)
          break
        case events.loadend:
          newState.loading = false
          newState.loaded = true
          newState.ready = true
          break
        // Allow pause on lockscreen on iOS
        case events.pause:
          newState.paused = true
          break
        default:
        // do nothing
      }

      return newState
    default:
      return state
  }
}

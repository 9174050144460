import React from 'react'

import data from '../config.json'

const ContactUsButton = () => {
  return (
    <a href={'mailto:' + data.email} className="btn btn-info"><span className="glyphicon glyphicon-envelope" /> Contact us</a>
  )
}

export default ContactUsButton

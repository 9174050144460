import { State } from '../reducer'
import { loadZone } from '../helper/zone'
export const init = () => {
  return (dispatch: any, getState: () => State) => {
    // case already initiated, be sure to not init twice
    if (getState().app.initiated) {
      return
    }
    const zoneId = window.location.search.substr(1)
    const apiVersion = getState().app.apiVersion

    loadZone(zoneId, apiVersion)
      .then(zone =>
        dispatch({
          type: 'ZONE',
          zone,
        })
      )
      .catch(error => {
        dispatch({
          type: 'ZONE_ERROR',
          error,
        })
      })
  }
}

export const update = (zoneId: string) => {
  return (dispatch: any, getState: () => State) => {
    const apiVersion = getState().app.apiVersion
    loadZone(zoneId, apiVersion)
      .then(zone => {
        return dispatch({
          type: 'ZONE_UPDATED',
          zone,
        })
      })
      .catch(error => {
        dispatch({
          type: 'ZONE_UPDATE_ERROR',
          error,
        })
      })
  }
}

export const setAdmin = (payload: any) => {
  return { type: 'SET_ADMIN', payload }
}

export default init

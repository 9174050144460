import { setPlayed, findCurrentPlaylist, getNextTrack } from '../helper/playlist'
import { Playlist, Track, Zone } from '../model/model'

export type State = {
  playlists: Playlist[]
  playlist: Playlist | null
  track: Track | null
  nextTrack: Track | null
}

const initialState: State = {
  playlists: [],
  playlist: null,
  track: null,
  nextTrack: null,
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'ZONE':
      const zone: Zone = action.zone
      const playlists = zone.playlists
      const playlist = findCurrentPlaylist(playlists)
      const nextTrack = getNextTrack(playlist)
      return {
        ...state,
        playlist,
        track: nextTrack,
        nextTrack,
        playlists,
      }
    case 'ZONE_UPDATED':
      return {
        ...state,
        playlists: action.zone.playlists,
      }
    case 'TRACK_NEXT':
      const currentPls = findCurrentPlaylist(state.playlists)
      const track = state.nextTrack
      if (track && currentPls) {
        setPlayed([track], state.playlists)
        return {
          ...state,
          track,
          playlist: currentPls,
          nextTrack: getNextTrack(currentPls),
        }
      }

    default:
      return state
  }
}

import app, { State as AppState } from './app'
import audio, { State as AudioState } from './audio'
import playlist, { State as PlaylistState} from './playlist'

export type reducers = {
  app: (state: AppState | undefined, action: any) => AppState,
  audio: (state: AudioState | undefined, action: any) => AudioState,
  playlist: (state: PlaylistState | undefined, action: any) => PlaylistState,
}

export type State = {
  app: AppState,
  audio: AudioState,
  playlist: PlaylistState,
}

const reducers: reducers = {
  app,
  audio,
  playlist,
}

export default reducers
